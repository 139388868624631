<template>
  <div class="drivers-step2-content">
    <div class="bg-white flex justify-between">
      <div class="d-flex flex-col pl-24 py-8">
        <label class="text-18 text-main font-500">从业资格证</label>
        <tos-uploader
          class="driver-card-min mt-12"
          v-model="driverQuaMainFileList"
          :max-count="1"
          @afterUploaded="afterUploaded"
          bgType="congyezigezheng-zhu"
        ></tos-uploader>
      </div>
      <div class="d-flex flex-col pr-24 py-8">
        <label class="text-18 text-main font-500">其他页</label>
        <tos-uploader
          class="driver-card-min mt-12"
          v-model="driverQuaSideFileList"
          :max-count="1"
          bgType="congyezigezheng-zhu"
        ></tos-uploader>
      </div>
    </div>
    <van-form class="px-12" ref="form">
      <template v-for="(item, key) in FormObj">
        <van-field
          v-if="key === 'qualificationNumber' && !item.hidden"
          :key="key"
          required
          v-model="userVertifiedRef[key]"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="'请输入'"
          :rules="item.rules"
        >
          <template #label>
            <div :class="{ titleError: userVertifiedRef.reasonKeys.includes(key) }">
              <div>{{ item.label }}</div>
              <div class="font-12" v-if="userVertifiedRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
          <template v-if="item.tips !== undefined" #left-icon>
            <div @click="FormObj[key].tips = true">
              <Icon name="xxts" svgClass="mr-4" />
            </div>
          </template>
        </van-field>
        <van-field
          :key="key"
          :value="qualificationDateStr"
          @click="datePopShowRef = true"
          :name="key"
          input-align="right"
          type="text"
          :rules="item.rules"
          :label="item.label"
          readonly
          :required="(isReview || isTypeAB) && isQualRequired"
          v-if="key === 'qualificationLicenceEndDate' && !item.hidden"
        >
        </van-field>
        <van-field
          :key="key"
          :value="userVertifiedRef[key] || '请选择'"
          @click="selectPopShowRef = true"
          :name="key"
          input-align="right"
          type="text"
          :label="item.label"
          readonly
          is-link
          :required="isReview"
          v-if="key === 'qualificationLicenseIntegrityAssessmentRecord' && !item.hidden"
        >
        </van-field>
        <van-field
          :key="key"
          :value="userVertifiedRef[key] || '请选择'"
          @click="selectPopShowRef2 = true"
          :name="key"
          input-align="right"
          type="text"
          :label="item.label"
          readonly
          is-link
          v-if="key === 'continuingEducationLevel' && !item.hidden"
        >
        </van-field>
        <van-field
          :key="key"
          :value="assessmentDateStr"
          @click="assDatePopShowRef = true"
          :name="key"
          input-align="right"
          type="text"
          :rules="item.rules"
          :label="item.label"
          readonly
          :required="isReview"
          v-if="key === 'qualificationLicenseIntegrityAssessmentValidTo' && !item.hidden"
        >
        </van-field>
        <van-field
          :key="key"
          :value="educationDateStr"
          @click="educationPopShowRef = true"
          :name="key"
          input-align="right"
          type="text"
          :rules="item.rules"
          :label="item.label"
          readonly
          v-if="key === 'continuingEducationEndDate' && !item.hidden"
        >
        </van-field>
        <van-field
          v-if="key === 'qualificationLicenseIssueOrganization' && !item.hidden"
          :key="key"
          v-model="userVertifiedRef[key]"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="'请输入'"
          :rules="item.rules"
          :required="isReview"
        ></van-field>
        <van-field
          v-if="key === 'qualificationLicenseAddress' && !item.hidden"
          :key="key"
          v-model="userVertifiedRef[key]"
          input-align="right"
          :name="key"
          :label="item.label"
          :placeholder="'请输入'"
          :rules="item.rules"
        ></van-field>
        <van-field
          :key="key"
          :value="userVertifiedRef[key] || '请选择'"
          @click="selectPopShowRef3 = true"
          :name="key"
          input-align="right"
          type="text"
          :label="item.label"
          readonly
          is-link
          :required="isReview"
          v-if="key === 'qualificationLicenseType' && !item.hidden"
        >
        </van-field>
        <van-field
          :key="key"
          :value="driveTypeStr || '请选择'"
          @click="typePopShowRef = true"
          :name="key"
          input-align="right"
          type="text"
          :label="item.label"
          readonly
          is-link
          :required="isReview"
          v-if="key === 'qualificationLicenseDriveType' && !item.hidden"
        >
        </van-field>
        <van-field
          :key="key"
          :value="userVertifiedRef[key]"
          @click="showDateRef = true"
          :name="key"
          input-align="right"
          type="text"
          :label="item.label"
          readonly
          is-link
          :rules="item.rules"
          :required="isReview"
          v-if="key === 'qualificationLicenseInitialIssueDate' && !item.hidden"
        >
        </van-field>
      </template>
    </van-form>

    <!-- tips弹层 -->
    <Popup position="bottom" round :style="{ height: '30%' }" v-model="FormObj.qualificationNumber.tips">
      <div class="popcard pa-16">
        <div class="pop-title text_muted">从业资格证号</div>
        <div class="pop-content font-16 mt-24">
          总质量4.5吨及以下车辆请填写：“驾驶员身份证前6位+12个0”
        </div>
        <div class="text-center mt-20" @click="FormObj.qualificationNumber.tips = false">
          <Button size="large" type="primary">知道了</Button>
        </div>
      </div>
    </Popup>
    <DatetimePicker
      v-model="datePopShowRef"
      :startDate="userVertifiedRef.qualificationLicenceStartDate"
      :endDate="userVertifiedRef.qualificationLicenceEndDate"
      @change="onDatetimePickerChange"
      :showFooter="false"
      :showClear="true"
    />

    <DatetimePicker
      v-model="assDatePopShowRef"
      :startDate="userVertifiedRef.qualificationLicenseIntegrityAssessmentValidFrom"
      :endDate="userVertifiedRef.qualificationLicenseIntegrityAssessmentValidTo"
      @change="onAssDatetimePickerChange"
      :showFooter="false"
      :showClear="true"
    />
    <DatetimePicker
      v-model="educationPopShowRef"
      :startDate="userVertifiedRef.continuingEducationStartDate"
      :endDate="userVertifiedRef.continuingEducationEndDate"
      @change="onEducationPickerChange"
      :showFooter="false"
      :showClear="true"
    />
    <Popup v-model="selectPopShowRef" position="bottom">
      <Picker
        title="诚信考核等级"
        show-toolbar
        cancel-button-text="清空"
        :columns="['A级', 'AA级', 'AAA级']"
        @confirm="onSelectConfirm"
        @cancel="onClearSelect"
      />
    </Popup>
    <Popup v-model="selectPopShowRef3" position="bottom">
      <Picker
        title="从业资格类别"
        show-toolbar
        cancel-button-text="清空"
        :columns="['道路货物运输驾驶员', '货运驾驶员', 'J-货运']"
        @confirm="onSelectConfirm3"
        @cancel="onClearSelect3"
      />
    </Popup>
    <Popup v-model="selectPopShowRef2" position="bottom">
      <Picker
        title="继续教育等级"
        show-toolbar
        cancel-button-text="清空"
        :columns="['合格', '无']"
        @confirm="onSelectConfirm2"
        @cancel="onClearSelect2"
      />
    </Popup>
    <Popup v-model="typePopShowRef" round position="bottom">
      <ChecksPorps
        title="选择准驾车型"
        :value="driveTypeStr"
        :columns="[
          ['A2', 'B2', 'C1'],
          ['A1', 'A3', 'B1', 'C2', 'D', 'C3', 'C4', 'F', 'M', 'N', 'E']
        ]"
        @confirm="onTypeConfirm"
        @cancel="typePopShowRef = false"
        show-clear
      />
    </Popup>

    <Popup v-model="showDateRef" position="bottom">
      <div class="px-16 py-8">
        <van-button size="small" type="primary" class=" h-28" @click="handleDateConfirm(null)">清空</van-button>
      </div>
      <VantDate
        title=""
        type="date"
        :min-date="minDate"
        :maxDate="maxDate"
        v-model="currentDate"
        show-toolbar
        @confirm="handleDateConfirm"
        @cancel="showDateRef = false"
      />
    </Popup>
    <!-- 按钮栏 -->
    <div>
      <div class="truck-btn-box">
        <Button size="large" :loading="!next" @click="handleToNext" type="primary">提交认证</Button>
      </div>
    </div>
  </div>
</template>

<script>
// import useCheckFormValid from '@hook/useCheckFormValid';

import { defineComponent, ref, computed, onMounted, reactive } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import DatetimePicker from '@/components/DatetimePicker';
import { Button, Popup, Toast, Picker, DatetimePicker as VantDate } from 'vant';
import { useDriverVerifiedStore } from '@/store/driverVerfied';
import { PageEnum } from '@/enums/pageEnum';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { buildFormByAuthModeFields } from '@/utils/authMode';
import { apiOcrQualification } from '@/api/ocr';
import { formatQualificationOcr } from '@/utils/ocr';
import { isExpiredDay } from '@/utils/validateTimeRanges';
import { useUserStore } from '@/store/user';
import { licenseOrg, isBeforeThan } from '../constants';
import ChecksPorps from './ChecksPorps';
export default defineComponent({
  name: 'customer',
  components: {
    Button,
    TosUploader,
    Popup,
    DatetimePicker,
    Picker,
    ChecksPorps,
    VantDate
  },
  setup(_, ctx) {
    // 如果本地缓存有数据，从缓存中获取
    const userVerifiedStore = useDriverVerifiedStore();
    const driverQuaMainFileList = ref([]);
    const driverQuaSideFileList = ref([]);
    const userInfoStore = useUserStore();
    const isQualRequired = computed(() => {
      return userVerifiedStore.quaExpireConfig;
    });
    const isReview = !!userInfoStore.showReviewKeys;
    const isTypeAB = computed(() => {
      const { driveType } = userVerifiedStore.verifiedInfo;
      console.log(driveType);
      if (!driveType) return false;
      return driveType.indexOf('A') > -1 || driveType.indexOf('B') > -1;
    });
    const form = {
      qualificationNumber: {
        label: '从业资格证号',
        tips: false,
        rules: [{ required: true, message: '请输入从业资格证号' }]
      },
      qualificationLicenceEndDate: {
        label: '资格证有效期',
        hidden: false,
        rules: [
          {
            validator: e => {
              const { qualificationLicenceEndDate } = userVerifiedStore.verifiedInfo;
              if (qualificationLicenceEndDate && isExpiredDay(qualificationLicenceEndDate)) {
                return false;
              }
              return true;
            },
            message: '从业资格证已过期'
          },
          {
            validator: e => {
              const { qualificationLicenceEndDate } = userVerifiedStore.verifiedInfo;
              if (!qualificationLicenceEndDate && isTypeAB.value && isQualRequired.value) {
                return false;
              }
              return true;
            },
            message: '请选择资格证有效期'
          }
        ]
      },
      qualificationLicenseInitialIssueDate: {
        label: '初次领证日期',
        hidden: false,
        rules: [
          {
            validator: () => {
              const { qualificationLicenseInitialIssueDate } = userVerifiedStore.verifiedInfo;
              if (
                qualificationLicenseInitialIssueDate &&
                !isBeforeThan(qualificationLicenseInitialIssueDate, Date.now())
              )
                return false;
              return true;
            },
            message: '初次领证日期≤当前时间'
          },
          {
            validator: () => {
              const {
                qualificationLicenceStartDate,
                qualificationLicenseInitialIssueDate
              } = userVerifiedStore.verifiedInfo;
              if (
                qualificationLicenseInitialIssueDate &&
                qualificationLicenceStartDate &&
                !isBeforeThan(qualificationLicenseInitialIssueDate, qualificationLicenceStartDate)
              )
                return false;
              return true;
            },
            message: '初次领证日期≤资格证有效期起始日期'
          }
        ]
      },

      qualificationLicenseIssueOrganization: {
        label: '资格证发证机关',
        rules: [
          {
            validator: value => {
              if (value && !licenseOrg.test(value)) return false;
              return true;
            },
            message: '发证机关不少于5位中文字符'
          }
        ]
      },
      qualificationLicenseAddress: {
        label: '资格证住址',
        rules: [{ validator: item => !item || item.length >= 5, message: '资格证住址不小于5位' }]
      },
      qualificationLicenseType: {
        label: '从业资格类别',
        hidden: false
      },
      qualificationLicenseDriveType: {
        label: '资格证准驾车型',
        hidden: false
      },
      qualificationLicenseIntegrityAssessmentRecord: {
        label: '诚信考核等级',
        hidden: false
      },
      qualificationLicenseIntegrityAssessmentValidTo: {
        label: '诚信考核有效期',
        hidden: false,
        rules: [
          {
            validator: () => {
              const { qualificationLicenseIntegrityAssessmentValidTo } = userVerifiedStore.verifiedInfo;
              if (
                qualificationLicenseIntegrityAssessmentValidTo &&
                isExpiredDay(qualificationLicenseIntegrityAssessmentValidTo)
              ) {
                return false;
              }
              return true;
            },
            message: '诚信考核已过期'
          }
        ]
      },
      continuingEducationLevel: {
        label: '继续教育等级',
        hidden: false
      },
      continuingEducationEndDate: {
        label: '继续教育有效期',
        hidden: false,
        rules: [
          {
            validator: () => {
              const { continuingEducationEndDate } = userVerifiedStore.verifiedInfo;
              if (continuingEducationEndDate && isExpiredDay(continuingEducationEndDate)) {
                return false;
              }
              return true;
            },
            message: '继续教育已过期'
          }
        ]
      }
    };
    const qualificationDateStr = computed(() => {
      const { qualificationLicenceEndDate, qualificationLicenceStartDate } = userVerifiedStore.verifiedInfo;
      if (typeof qualificationLicenceEndDate == 'number' && typeof qualificationLicenceStartDate === 'number') {
        return `${formatToDateTime(qualificationLicenceStartDate, 'yyyy/MM/dd')} - ${formatToDateTime(
          qualificationLicenceEndDate,
          'yyyy/MM/dd'
        )}`;
      }
      return '请选择';
    });

    const assessmentDateStr = computed(() => {
      const {
        qualificationLicenseIntegrityAssessmentValidTo,
        qualificationLicenseIntegrityAssessmentValidFrom
      } = userVerifiedStore.verifiedInfo;
      if (
        typeof qualificationLicenseIntegrityAssessmentValidFrom == 'number' &&
        typeof qualificationLicenseIntegrityAssessmentValidTo === 'number'
      ) {
        return `${formatToDateTime(
          qualificationLicenseIntegrityAssessmentValidFrom,
          'yyyy/MM/dd'
        )} - ${formatToDateTime(qualificationLicenseIntegrityAssessmentValidTo, 'yyyy/MM/dd')}`;
      }
      return '请选择';
    });

    const educationDateStr = computed(() => {
      const { continuingEducationEndDate, continuingEducationStartDate } = userVerifiedStore.verifiedInfo;
      if (typeof continuingEducationStartDate == 'number' && typeof continuingEducationEndDate === 'number') {
        return `${formatToDateTime(continuingEducationStartDate, 'yyyy/MM/dd')} - ${formatToDateTime(
          continuingEducationEndDate,
          'yyyy/MM/dd'
        )}`;
      }
      return '请选择';
    });

    buildFormByAuthModeFields(form, userVerifiedStore.authModeFields);
    const FormObj = reactive(form);
    if (!userInfoStore.showReviewKeys) {
      const reviewKeys = [
        'qualificationLicenseType',
        'qualificationLicenseDriveType',
        'qualificationLicenseAddress',
        'qualificationLicenseInitialIssueDate',
        'qualificationLicenseIssueOrganization',
        'continuingEducationLevel',
        'continuingEducationEndDate',
        'qualificationLicenseIntegrityAssessmentRecord',
        'qualificationLicenseIntegrityAssessmentValidTo'
      ];
      reviewKeys.forEach(key => {
        const obj = FormObj[key];
        if (obj) {
          obj.hidden = true;
        }
      });
    }
    const next = ref(true);

    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);
    const datePopShowRef = ref(false);
    const assDatePopShowRef = ref(false);
    const educationPopShowRef = ref(false);

    onMounted(() => {
      // 如果缓存中有图片需要进行回填
      if (userVerifiedStore?.verifiedInfo?.qualificationLicenceMainUrl) {
        driverQuaMainFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.qualificationLicenceMainUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (userVerifiedStore?.verifiedInfo?.qualificationLicenceSideUrl) {
        driverQuaSideFileList.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.qualificationLicenceSideUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }

      if (!userVerifiedStore?.verifiedInfo?.qualificationNumber) {
        let str = '';
        if (driveTypeStatus()) {
          str = `${userVerifiedStore?.verifiedInfo?.idCard.slice(0, 6)}000000000000`;
        } else {
          str = userVerifiedStore?.verifiedInfo?.idCard;
        }
        userVerifiedStore.updateInfo({ qualificationNumber: str });
      }
    });

    const driveTypeStatus = () => {
      const d = userVerifiedStore?.verifiedInfo?.driveType;
      let arr = d.split(',');
      let status = arr.every(item => {
        return item.indexOf('C') !== -1;
      });
      return status;
    };

    const handleToNext = async () => {
      userVerifiedStore.updateInfo({
        qualificationLicenceMainUrl: driverQuaMainFileList?.value[0]?.fileName || '',
        qualificationLicenceSideUrl: driverQuaSideFileList?.value[0]?.fileName || ''
      });
      userVerifiedStore.saveInfo();
      const isPass = await validateAll(true);
      if (!isPass) {
        return;
      }
      // 校验一下
      if (userVerifiedStore.authModeFields.qualificationNumber && !userVertifiedRef.value.qualificationNumber) {
        Toast.fail('请填写从业资格证号！');
        return;
      }
      if (FormObj.qualificationLicenceEndDate && !FormObj.qualificationLicenceEndDate.hidden) {
        const pass = FormObj.qualificationLicenceEndDate.rules?.[0]?.validator?.();
        if (!pass) {
          Toast.fail(FormObj.qualificationLicenceEndDate.rules?.[0]?.message);
          return;
        }
      }

      if (
        FormObj.qualificationLicenseIntegrityAssessmentValidTo &&
        !FormObj.qualificationLicenseIntegrityAssessmentValidTo.hidden
      ) {
        const pass = FormObj.qualificationLicenseIntegrityAssessmentValidTo.rules?.[0]?.validator?.();
        if (!pass) {
          Toast.fail(FormObj.qualificationLicenseIntegrityAssessmentValidTo.rules?.[0]?.message);
          return;
        }
      }
      const keys = [
        { key: 'qualificationLicenceEndDate', label: '资格证有效期' },
        { key: 'qualificationLicenseInitialIssueDate', label: '初次领证日期' },
        { key: 'qualificationLicenseIssueOrganization', label: '资格证发证机关' },
        { key: 'qualificationLicenseType', label: '从业资格类别' },
        { key: 'qualificationLicenseDriveType', label: '资格证准驾车型', type: 'array' },
        { key: 'qualificationLicenseIntegrityAssessmentRecord', label: '诚信考核等级' },
        { key: 'qualificationLicenseIntegrityAssessmentValidTo', label: '诚信考核有效期' }
      ];
      if (isReview) {
        let flag = false;
        keys.forEach(({ key, label, type }) => {
          const isEmpty = type !== 'array' ? !userVertifiedRef.value[key] : !userVertifiedRef.value[key]?.length;
          if (FormObj[key]?.hidden || !isEmpty || flag) return;
          Toast.fail(`${label}不能为空`);
          flag = true;
          return;
        });
        if (flag) return;
      }

      next.value = false; // 加载中

      if (ctx.root.$route.query.isEdit === 'true') {
        // 调用更新接口
        await userVerifiedStore.updateVerifiedInfo(() => {
          ctx.root.$router.replace(PageEnum.BASE_DRIVER_AUTH_RESULT);
        });
      } else {
        await userVerifiedStore.submitVerifiedInfo(() => {
          ctx.root.$router.replace(PageEnum.BASE_DRIVER_AUTH_RESULT);
        });
      }
      next.value = true;
    };
    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      if (form) {
        try {
          await form.validate(...params);
          return true;
        } catch (e) {
          return false;
        }
      }
      return true;
    };
    const validateAll = async (showToast = false) => {
      const showKeys = Object.keys({ ...FormObj }).filter(key => !FormObj[key].hidden);
      const form = ctx.refs.form;
      if (!form || !showKeys.length) return true;
      if (form) {
        try {
          await form.validate(showKeys);
          return true;
        } catch (e) {
          if (showToast && Array.isArray(e) && e.length > 0) {
            Toast.fail(e[0].message);
          }
          return false;
        }
      }
    };
    const onDatetimePickerChange = ({ start, end }) => {
      userVerifiedStore.updateInfo({
        qualificationLicenceStartDate: start,
        qualificationLicenceEndDate: end
      });
      validateForm('qualificationLicenceEndDate');

      // console.log(userVerifiedStore.verifiedInfo);
    };

    const onAssDatetimePickerChange = ({ start, end }) => {
      userVerifiedStore.updateInfo({
        qualificationLicenseIntegrityAssessmentValidFrom: start,
        qualificationLicenseIntegrityAssessmentValidTo: end
      });
      validateForm('qualificationLicenseIntegrityAssessmentValidTo');

      // console.log(userVerifiedStore.verifiedInfo);
    };

    const onEducationPickerChange = ({ start, end }) => {
      userVerifiedStore.updateInfo({
        continuingEducationStartDate: start,
        continuingEducationEndDate: end
      });
      validateForm('continuingEducationEndDate');

      // console.log(userVerifiedStore.verifiedInfo);
    };

    const afterUploaded = async file => {
      file.status = 'uploading';
      file.message = '识别中';
      try {
        const res = await apiOcrQualification({ imageUrl: file.url });
        const { data } = res;
        const {
          qualificationNumber,
          qualificationLicenceEndDate,
          qualificationLicenceStartDate
        } = formatQualificationOcr(data);
        const obj = {};
        if (qualificationNumber) {
          obj.qualificationNumber = qualificationNumber;
        }
        if (qualificationLicenceEndDate && qualificationLicenceStartDate) {
          obj.qualificationLicenceStartDate = qualificationLicenceStartDate;
          obj.qualificationLicenceEndDate = qualificationLicenceEndDate;
        }
        userVerifiedStore.updateInfo(obj);
      } finally {
        file.status = 'done';
      }
    };
    const selectPopShowRef = ref(false);
    const onSelectConfirm = item => {
      userVerifiedStore.updateInfo({
        qualificationLicenseIntegrityAssessmentRecord: item
      });
      selectPopShowRef.value = false;
    };
    const onClearSelect = () => {
      userVerifiedStore.updateInfo({
        qualificationLicenseIntegrityAssessmentRecord: null
      });
      selectPopShowRef.value = false;
    };

    const selectPopShowRef2 = ref(false);
    const onSelectConfirm2 = item => {
      userVerifiedStore.updateInfo({
        continuingEducationLevel: item
      });
      selectPopShowRef2.value = false;
    };
    const onClearSelect2 = () => {
      userVerifiedStore.updateInfo({
        continuingEducationLevel: null
      });
      selectPopShowRef2.value = false;
    };
    const selectPopShowRef3 = ref(false);
    const onSelectConfirm3 = item => {
      userVerifiedStore.updateInfo({
        qualificationLicenseType: item
      });
      selectPopShowRef3.value = false;
    };
    const onClearSelect3 = () => {
      userVerifiedStore.updateInfo({
        qualificationLicenseType: null
      });
      selectPopShowRef3.value = false;
    };
    const driveTypeStr = computed(() => {
      const { qualificationLicenseDriveType } = userVerifiedStore.verifiedInfo;
      if (!qualificationLicenseDriveType || !qualificationLicenseDriveType.length) return '';
      return qualificationLicenseDriveType.join(',');
    });
    const typePopShowRef = ref(false);
    const onTypeConfirm = e => {
      const value = e ? e.split(',') : [];
      userVerifiedStore.updateInfo({
        qualificationLicenseDriveType: value
      });
    };

    const currentDate = ref(new Date());
    const maxDate = ref(new Date('2199/12/31'));
    const minDate = ref(new Date('1970/01/01'));
    const showDateRef = ref(false);
    const handleDateConfirm = e => {
      userVerifiedStore.updateInfo({
        qualificationLicenseInitialIssueDate: e ? formatToDateTime(e, 'yyyy-MM-dd') : null
      });
      showDateRef.value = false;
      validateForm('qualificationLicenseInitialIssueDate');
    };
    return {
      next,
      FormObj,
      handleToNext,
      userVertifiedRef,
      driverQuaMainFileList,
      driverQuaSideFileList,
      qualificationDateStr,
      datePopShowRef,
      assessmentDateStr,
      assDatePopShowRef,
      onDatetimePickerChange,
      onAssDatetimePickerChange,
      afterUploaded,
      selectPopShowRef,
      onSelectConfirm,
      onClearSelect,
      educationDateStr,
      onEducationPickerChange,
      educationPopShowRef,
      selectPopShowRef2,
      onSelectConfirm2,
      onClearSelect2,
      selectPopShowRef3,
      onSelectConfirm3,
      onClearSelect3,
      typePopShowRef,
      onTypeConfirm,
      driveTypeStr,
      currentDate,
      maxDate,
      minDate,
      showDateRef,
      handleDateConfirm,
      isReview,
      isTypeAB,
      isQualRequired
    };
  }
});
</script>
<style lang="less">
.drivers-step2-content {
  .date-picker {
    font-size: 0.16rem;
    .label {
      color: #646566;
    }
  }
  .driver-card-wrap {
    padding: 0.08rem 0.16rem;
    .driver-card {
      width: 3.3rem;
      height: 1.98rem;
    }
  }
  .driver-card-min {
    width: 1.55rem;
    height: 0.9rem;
    background: #f6f8fa;
    border-radius: 0.08rem;
  }
}
</style>
